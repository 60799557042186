import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectionStrategy, Component, Input } from '@angular/core';
import Glide from '@glidejs/glide';
import { LocalSettingsService } from '@services/settings.service';
import { CommonUtils } from '@utils/common.utils';
import { map } from 'rxjs';
import { SidreriaComponent } from '../sidreria/sidreria.component';
import { NgFor } from '@angular/common';

@Component({
    selector: 'cmp-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: true,
    imports: [NgFor, SidreriaComponent],
})
export class CarouselComponent implements AfterViewInit {
  /**
   * Data list
   */
  @Input() data: any[] = [];
  @Input() translations: any[] = [];
  labels = {};

  constructor(private settingsService: LocalSettingsService, private responsive: BreakpointObserver) {
    this.settingsService.currentLanguage.subscribe((lang: string) => {
      this.setTranslations(lang);
    });
  }

  setTranslations(lang: string) {
    this.labels['persons'] = CommonUtils.getTranslation(this.translations, 'main_common_persons', lang);
    this.labels['stand'] = CommonUtils.getTranslation(this.translations, 'main_stand', lang);
    this.labels['sit'] = CommonUtils.getTranslation(this.translations, 'main_sit', lang);
    this.labels['just_standing'] = CommonUtils.getTranslation(this.translations, 'main_just_sttanding', lang);
  }

  ngAfterViewInit() {
    this.setTranslations(this.settingsService.getLanguage());
    const isMobile$ = this.responsive.observe([Breakpoints.Small, Breakpoints.XSmall]).pipe(map(res => res.matches));

    isMobile$.subscribe(isMobile => {
      setTimeout(() => {
        const glide = new Glide('#carousel-sidrerias', {
          autoplay: 3000,
          hoverpause: true,
          perView: isMobile ? 1 : 3,
          type: 'carousel',
          focusAt: isMobile ? 0 : 1,
        });

        glide.mount();
      }, 500);
    });
  }
}
